




































































































































































































































import { Vue, Component } from 'vue-property-decorator';

import { appName } from '@/env';
import {
    readDashboardMiniDrawer,
    readDashboardShowDrawer,
    readHasAdminAccess,
} from '@/store/main/getters';
import {
    commitSetDashboardShowDrawer,
    commitSetDashboardMiniDrawer
} from '@/store/main/mutations';
import {
    readImpersonated,
    readImpersonatedName,
    readAffiBrands,
} from '@/store/affi/getters';
import {
    dispatchUserLogOut,
} from '@/store/main/actions';
import {
  dispatchGetBrands,
} from '@/store/affi/actions';
import { IBrand } from '@/interfaces';


import { ttt } from '@/nutils';
import { store } from '@/store';

const routeGuardMain = async (to, from, next, isAdmin) => {
  if (to.path === '/main') {
    if (readHasAdminAccess(store)) {
        next('/main/admin/admindashboard');
    } else {
        next('/main/dashboard');
    }
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  public appName = appName;
  public ttt = ttt;


  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next, true);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next, true);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public get impersonated() {
    return readImpersonated(this.$store);
  }

  public get impersonatedName() {
    return readImpersonatedName(this.$store);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }

  public get merchantDisabled() {
    const imp = readImpersonated(this.$store);
    const bs = readAffiBrands(this.$store)
        .filter( (b) => ( imp === -1 || b && b.owner_id === imp ) );
    const brand : IBrand = bs[0];
    // console.log(brand);
    return !this.hasAdminAccess && (!brand || brand.payment_status !== 'approved');
  }

  public async mounted() {
    await dispatchGetBrands(this.$store);
  }
}
